@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: url("./App//Assets/Fonts/Poppins-Regular.ttf");
}

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins" !important;
}

input[type="text"][disabled] {
  color: black !important;
}

.sessionlayout .ant-input {
  background-color: #e9eff6 !important;
}

.font-light {
  font-size: smaller !important;
  font-weight: 100 !important;
}

.btn-boxShadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px !important;
}

.large-font {
  color: #000000;
  text-shadow: 1.5px 0 #000000;
  letter-spacing: 1px;
  font-size: 36px;
  font-weight: bold;
}

.bold-heading {
  font-size: 13px;
  font-weight: 700;
}

.view-profile-cont .ant-upload {
  height: 130px !important;
  width: 130px !important;
}

.viewaddress .ant-modal-content {
  padding: 0px !important;
}

.ant-table-cell {
  /* text-align: center !important; */
  padding: 15px !important;
}

.ant-table-thead {
  /* text-align: center !important; */
}


.item-Description .ant-table-thead {
  background-color: white !important;
}

.signup-image {
  background: url('./App/Assets/Images/LoginBg.png');
  background-size: contain;
  background-position: left;
  background-attachment: fixed;
  background-repeat: no-repeat;
  width: 100%;
}

.ant-badge .ant-badge-dot.ant-scroll-number {
  margin-left: -10px !important;
}

.admin-customer-table .ant-table .ant-table-row:nth-child(2n-1) {
  background-color: #F8FAFC;
  margin-top: 100px !important;
  padding: 0px !important;
}



.admin-customer-table .ant-table .ant-table-container .ant-table-content .ant-table-thead tr th {
  color: #061E3A !important;
  background-color: white !important;

}

.admin-customer-table .ant-table .ant-table-content .ant-table-tbody tr {
  height: 50px;
}

.admin-customer-table .ant-table .ant-table-row:hover {
  background: none;
}


/* Quotes Table Admin Table  */
.admin-customer-quotes-table .ant-table .ant-table-row:nth-child(2n-1) {
  background-color: #F8FAFC;
  margin-top: 100px !important;
  padding: 0px !important;
}

.admin-customer-quotes-table .ant-table .ant-table-container .ant-table-content .ant-table-thead tr th {
  color: white !important;
  background-color: #061E3A !important;
  font-weight: 100 !important;
}

.admin-customer-quotes-table .ant-table .ant-table-container .ant-table-content .ant-table-thead tr th ::before {
  color: #061E3A !important;
  background-color: #061E3A !important;
}

:where(.css-dev-only-do-not-override-ed5zg0) .ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
:where(.css-dev-only-do-not-override-ed5zg0) .ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: unset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.DashboardContainer::-webkit-scrollbar {
  display: none;
}

.productRecommendation::-webkit-scrollbar {
  display: none;
}

.item-Description::-webkit-scrollbar {
  display: none;
}

.hoverdiv {
  height: 0px;
  width: 0px;
  transition: 0.3s ease-in-out;
}

.Add-Product-div :hover .hoverdiv {
  height: 140px;
  width: 140px;
}



/* SideBar Design ----------------> */


.ant-menu-light.ant-menu-root.ant-menu-inline {
  border: none !important;
}

.ant-menu-submenu-title {
  margin: 0px !important;
}

.ant-menu-submenu-title {
  padding: 0px;
  padding-left: 10px !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
  gap: 17px;
}

.ant-menu-item {
  padding-left: 13px !important;
}



/* Responsive Designing */
.Session-Grid {
  grid-column: 2;
}

.SessionImageCont {
  display: block;
}

.header-heading {
  font-size: 30px;
}

.Modal-heading {
  font-size: 22px;
}


.support-heading {
  font-size: 14px;
}

.Layout-Content-Padding {
  padding: 10px 20px;
}

.Layout-Sidebar {
  position: fixed;
  width: 220px;
  top: 70px;
  padding: 10px 0px;
  border: 1px solid lightgray;
  background-color: white;
  transition: 1s ease-in-out;
  z-index: 99;
}

.Layout-MainContent {
  margin-left: 220px;
  padding-top: 10px;
  margin-top: 70px;
}

.Hamburger {
  display: block;
}


.signup-res {
  grid-template-columns: 33% 67%;
}

.login-res {
  grid-template-columns: 50% 50%;
}

.HeaderLogo {
  height: 45px;
}

.HeaderItems {
  font-size: 25px;
}


.Layout-Sidebar {
  position: absolute;
  overflow: hidden;
  width: 0px;
}

.recharts-legend-wrapper {
  display: none !important;
}

.text-gradient {
  /* background-image: linear-gradient(to bottom right, #030338, #090979, #00d4ff); */
}


/* Barchart Items Dashboard  */
.recharts-default-legend {
  display: flex;
  overflow-x: scroll;
}




.recharts-legend-item {
  font-size: 10px;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  width: fit-content !important;
  font-weight: bold;

}

.recharts-default-legend {
  justify-content: center !important;
}

.TableMain::-webkit-scrollbar {
  display: none;
}

.BarChartGraphData::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width:800px) {
  .header-heading {
    font-size: 18px;
  }

  .HeaderItems {
    font-size: 22px;
  }

  .HeaderLogo {
    height: 40px;
  }

  .support-heading {
    font-size: 8px;
  }

  .Layout-Content-Padding {
    padding: 5px 10px;
  }

  .Modal-heading {
    font-size: 15px;
  }
}

@media screen and (max-width:600px) {

  .status-box-cont {
    display: flex;
    justify-content: center;
    gap: 5px;
  }

  .SessionImageCont {
    display: none;
  }

  .Session-Grid {
    grid-column: 1
  }

  .signup-res {
    grid-template-columns: 0% 100%;
  }

  .login-res {
    grid-template-columns: 0% 100%;
  }
}

.ant-table-cell {
  white-space: nowrap !important;
}


@media screen and (max-width:500px) {
  .HeaderLogo {
    height: 25px;
  }



  .Hamburger {
    display: block;
  }

  .Modal-heading {
    font-size: 12px;
  }
}



/* Table Css  */
.TableMain {
  width: 100% !important;
  /* border: 1px solid black; */
}

.TableMain .ant-table {
  font-size: 13px;
  background-color: transparent !important;
}

.TableMain table tbody tr {
  background-color: white !important;
}

.ant-modal-wrap .ant-modal-centered {
  z-index: 99999999999999999999999 !important;
}

.TableMain .ant-table-tbody>tr>td {
  border-bottom: 5px solid transparent !important;
  border-top: 5px solid #F5F8FC !important;
}

.InvoiceModalCss>.ant-modal-content {
  padding: 0px !important;
}



.TableMain .ant-table-thead>tr>th:first-child {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  background-color: none;
}

.TableMain .ant-table-thead>tr>th:last-child {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.TableMain .ant-table-tbody>tr>td:first-child {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

.TableMain .ant-table-tbody>tr>td:last-child {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.TableMain .ant-table-thead>tr>th {
  background-color: white;
  padding: 10px;
  font-weight: bold;
}

.TableMain .ant-table-selection-column .ant-checkbox {
  padding-left: 10px !important;
}

.TableMain::-webkit-scrollbar {
  display: none;
}